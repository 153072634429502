.list-check li {
  background: url("../../../public/assets/icons/check-list.svg") no-repeat left
    center;
  padding: 1px 10px 5px 25px;
  list-style: none;
  margin-bottom: 10px;
  vertical-align: middle;
}

ul.list-check {
  padding-left: 1rem;
}

@media screen and (max-width: 767px) {
  .mh-400 {
    max-height: 400px !important;
  }
  .list-check li {
    margin-bottom: 5px;
  }
}
