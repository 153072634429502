.card-link {
  min-width: 200px;
  text-decoration: none;
}

.image-card {
  object-fit: cover;
  border-radius: 0 0 20px 20px;
}

.rounded20 {
  border-radius: 21px;
}

@media screen and (max-width: 340px) {
  .xxs-mobile-100 {
    width: 100%;
  }
}

.card-content {
  opacity: 1;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.card-content:hover {
  opacity: 0.8;
}
