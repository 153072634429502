@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 16px;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "Urbanist", sans-serif;
  }

  h1 {
    font-size: 42px;
    font-weight: bold;
  }

  h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 1em;
  }

  h3,
  .h3 {
    font-size: 22px;
    font-weight: bold;
  }
  h4,
  .h4 {
    font-size: 18px;
    font-weight: bold;
  }

  .l-light {
    font-size: 22px;
    font-weight: 300;
    line-height: 26px;
  }

  .m-regular {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }

  .s-regular {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  .s-bold {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  .xs-regular {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }

  .xs-bold {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .xxs-regular {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }

  .xxs-bold {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
  }

  .xxs-semi-bold {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
  }

  @media (max-width: 991px) {
    font-size: calc(16px * 0.9);
    h1 {
      font-size: calc(42px * 0.7);
    }

    h2 {
      font-size: calc(32px * 0.7);
    }

    h3,
    .h3 {
      font-size: calc(22px * 0.8);
    }
    h4,
    .h4,
    .l-light {
      font-size: calc(22px * 0.8);
    }

    .m-regular {
      font-size: calc(18px * 0.9);
    }

    .s-regular,
    .s-bold {
      font-size: calc(16px * 0.9);
    }
  }
}

.font-urbanist {
  font-family: "Urbanist", sans-serif;
}

.white-font {
  color: white;
}

.black-font {
  color: black;
}

.red-font {
  color: #d95040;
}

.lightblue-font {
  color: #326de6;
}

.blue-font {
  color: #4628dd;
}

.gray-font {
  color: #939393;
}

.darkgray-font {
  color: #7c7c7c;
}

.green-font {
  color: #2f4f37;
}

.yellow-font {
  color: #dba02a;
}

.orange-font {
  color: #fb8c49;
}

.orange-link {
  color: #fb8c49;

  &:hover {
    color: #fb8c49;
  }
}

.white-background {
  background-color: white;
}

.blue-background {
  background-color: #4628dd;
}

.dark-blue-background {
  background-color: #405275;
}

.yellow-background {
  background-color: #f9cb5e;
}

.brown-background {
  background-color: #b59885;
}

.aqua-background {
  background-color: #56ceb2;
}

.violet-background {
  background-color: #8242ea;
}

.taupe-background {
  background-color: #b58585;
}

.orange-background {
  background-color: #f9965e;
}

.magenta-background {
  background-color: #a428dd;
}

.gray-background {
  background-color: #f6f6f6;
}

.dark-gray-background {
  background-color: #626262;
}

.red-background {
  background-color: #d95040;
}

.black-background {
  background-color: #080607;
}
.dark-gray-background {
  background-color: #282b2e;
}
.hover-shadow:hover {
  -webkit-box-shadow: 5px 5px 24px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 5px 5px 24px 0px rgba(0, 0, 0, 0.25);
}

.clickable {
  cursor: pointer;
}

@media (max-width: 1200px) {
  .container {
    max-width: 1068px;
  }
}

.line-title {
  overflow: hidden;
}

.line-title:after {
  content: "";
  display: inline-block;
  width: 60px;
  border-bottom: 4px solid #fb8c48;
  margin: 6px 8px;
}

.line-title-h3 {
  overflow: hidden;
}

.line-title-h3:after {
  content: "";
  display: inline-block;
  width: 30px;
  border-bottom: 4px solid #fb8c48;
  margin: 4px 8px;
}

::-moz-selection {
  /*@bugfix mozilla*/
  background: #f9975eaf;
}
::selection {
  /* effet souris  */
  background: #f9975eaf;
}

// Carousel CSS
span.carousel-control-prev-icon,
span.carousel-control-next-icon {
  background-color: #808080;
  border-radius: 50%;
  background-position: 50%;
  background-size: 100% 50%;
}
.carousel-indicators [data-bs-target] {
  background-color: black;
}
.react-multi-carousel-dot button {
  border-color: black !important;
}

.react-multiple-carousel__arrow {
  background: rgb(0 0 0 / 30%) !important;
  min-width: 32px !important;
  min-height: 32px !important;

  &:hover {
    background-color: rgb(0 0 0 / 70%) !important;
  }
}
.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px) !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
}
