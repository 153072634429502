.icon-reseau {
  height: 60px;
}
.form-container {
  position: relative;
  max-width: 500px;

  h2 {
    margin-bottom: 50px;

    &:after {
      content: "";
      height: 15px;
      width: 15px;
      border: 1px orange solid;
      position: absolute;
      top: 5px;
      left: 175px;
      z-index: -1;
    }
  }

  .form-content {
    display: grid;

    input,
    textarea {
      background: none;
      font-size: 1.2rem;
      border: 1px solid rgb(104, 104, 104);
      border-radius: 5px;

      @media screen and (min-width: 1200px) {
        font-size: 1.5rem;
      }
    }

    input {
      padding: 6px;
      margin-bottom: 20px;
      height: 40px;
    }

    select {
      height: 40px;
      margin-bottom: 20px;
    }
  }

  textarea {
    resize: none;
    padding: 6px 0;
    height: 70px;

    @media screen and (max-width: 540px) {
      width: 75%;
      padding: 12px 0;
    }
  }

  input.button {
    background: #fb8c48;
    height: 55px;
    width: 126px;
    margin-top: 20px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: 0.2s;
    border: none;
  }
}

.formMessage {
  p {
    text-align: left;
    border-radius: 20px;
    text-align: center;
    padding: 5px 10px;
  }
}

.success {
  background-color: rgb(139, 248, 139);
}

.error {
  background-color: rgb(246, 147, 147);
}

::placeholder {
  color: rgba(0, 0, 0, 0.2);
  font-size: 0.8em;
}
