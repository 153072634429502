.card-link {
  text-align: -webkit-center;
}
.card-content {
  max-width: 400px;
}

.carousel-review {
  padding-bottom: 40px;
}
