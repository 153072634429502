.navbar {
  background-color: black;
  position: relative;
  border-bottom: 1px solid gray;
  padding: 0px;
}

.logo img {
  height: 80px;
}

.menu-icon {
  display: none;
  border: 1px solid #555555;
  padding: 2px;
  border-radius: 3px;
}

span.cross {
  display: none;
}

.menu-icon.active span.hamburger {
  display: none;
}

.menu-icon.active span.cross {
  display: block;
  transform: rotate(45deg);
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding-top: 15px;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 30px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #fb8c49;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fb8c49;
}

@media (max-width: 992px) {
  .logo img {
    height: 60px;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    z-index: 5;
    position: absolute;
    right: 0;
    top: 60px;
    background-color: black;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
    margin-top: 1px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}
