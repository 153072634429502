.ban-header {
  display: flex;
  align-items: center;
  min-height: 200px;
}

.ban-header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
}
