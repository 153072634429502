.card-style {
  margin: 0 10px;
  padding: 20px 10px;
  background-color: #f3f3f3;
}

span.react-read-more-read-less.react-read-more-read-less-more,
span.react-read-more-read-less.react-read-more-read-less-less {
  display: block !important;
  color: #939393;
  font-size: 14px;
}
