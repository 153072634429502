body {
  .btn {
    padding: 10px 20px;
    border: 1px solid transparent;

    &:hover {
      cursor: pointer;
    }
  }

  .btn-primary {
    --bs-btn-color: black;
    --bs-btn-bg: #fb8c49;
    --bs-btn-border-color: #fb8c49;
    --bs-btn-hover-bg: #fb8c49;
    --bs-btn-hover-color: black;
    --bs-btn-hover-border-color: #fb8c49;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-bg: #fb8c49;
    --bs-btn-active-border-color: #fb8c49;
    --bs-btn-disabled-bg: #cacaca;
    --bs-btn-disabled-border-color: #cacaca;

    &:hover {
      -webkit-box-shadow: 0px 0px 1px 4px rgba(221, 118, 40, 0.2);
      box-shadow: 0px 0px 1px 4px rgba(221, 118, 40, 0.2);
      -ms-transform: scale(1.03); /* IE 9 */
      -webkit-transform: scale(1.03); /* Safari 3-8 */
      transform: scale(1.03);
      transition: transform 0.3s; /* Animation */
    }
  }

  .btn-secondary {
    --bs-btn-color: white;
    --bs-btn-bg: black;
    --bs-btn-border-color: black;
    --bs-btn-hover-color: white;
    --bs-btn-hover-bg: black;
    --bs-btn-hover-border-color: black;
    --bs-btn-active-color: white;
    --bs-btn-active-bg: black;
    --bs-btn-active-border-color: black;
    --bs-btn-disabled-color: rgba(151, 151, 151, 0.5);
    --bs-btn-disabled-bg: rgba(151, 151, 151, 0);
    --bs-btn-disabled-border-color: rgba(151, 151, 151, 0);

    &:hover {
      -ms-transform: scale(1.03); /* IE 9 */
      -webkit-transform: scale(1.03); /* Safari 3-8 */
      transform: scale(1.03);
      transition: transform 0.3s; /* Animation */
    }
  }
}
