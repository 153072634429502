@media (min-width: 992px) {
  .menu-footer {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}

.menu-list {
  text-decoration: none;
  margin-bottom: 3px;
}

.menu-list a {
  color: white;
  text-decoration: none;
}

a {
  color: white;

  &:hover {
    color: white;
  }
}
